import * as React from "react"
import Homepage from '../../../gatsby-theme-emma/src/components/homepage';

type Props = {
  data: {
    allPodcast: any
    [key: string]: string
  }
  [key: string]: any
}

export default function EmmaCoreHomepage({ ...props }: Props) {
  const {
    data: { allPodcast },
  } = props

  return <Homepage podcasts={allPodcast.nodes} {...props} />
}
