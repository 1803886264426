import { graphql } from "gatsby"
import HomepageComponent from "../components/homepage"

export default HomepageComponent

export const query = graphql`
  query($formatString: String!) {
    allPodcast(sort: { fields: date, order: DESC }) {
      nodes {
        title
        description
        date(formatString: $formatString)
        link
      }
    }
  }
`
