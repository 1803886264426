/** @jsx jsx */
import { jsx, Container, Themed, Flex, Box } from "theme-ui";
// import { useTrail } from "react-spring"
import Layout from "./layout";
import ProjectItem from "./project-item";
import { IGatsbyImageData, GatsbyImage, StaticImage } from "@sidarthar/gatsby-plugin-image-2";
import ApplePodcastsIcon from "../../../../static/apple_podcasts_badge.svg";
import SpotifyIcon from "../../../../static/spotify-podcast-badge.svg";

type PodcastsProps = {
  podcasts: {
    title: string;
    description: string;
    date: string;
    link: string;
    cover: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  }[];
  [key: string]: any;
};

type PodcastProps = {
  podcast: {
    title: string;
    description: string;
    date: string;
    link: string;
    cover: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
};

const Homepage = ({ podcasts }: PodcastsProps) => {
  if (podcasts.length === 0) {
    return (
      <Layout>
        <Container>
          <Themed.p>There are no podcasts available at this time.</Themed.p>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <StaticImage
        style={{ height: "500px" }}
        imgStyle={{ height: "500px" }}
        layout="fullWidth"
        src="../haiti_0050.jpg"
        alt="Light from Light"
      />
      <Container>
        <Themed.h1 sx={{ textAlign: "center" }}>
          Overseas: A collection of Haitian stories and medical cases
        </Themed.h1>
        <Themed.p sx={{ textAlign: "center" }}>
          A podcast by an American couple living in Port-au-Prince, Haiti
          telling stories from their time abroad. A new episode is published
          every Wednesday.
        </Themed.p>
        <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
          <Box sx={{ marginLeft: "1rem", marginRight: "1rem" }}>
            <a
              href="https://podcasts.apple.com/us/podcast/overseas/id1549778845"
              target="_blank"
              rel="noopener"
            >
              <ApplePodcastsIcon />
            </a>
          </Box>
          <Box sx={{ marginLeft: "1rem", marginRight: "1rem" }}>
            <a
              href="https://open.spotify.com/show/5FhNDe0Tibe1iJOFc8KvFO"
              target="_blank"
              rel="noopener"
            >
              <SpotifyIcon />
            </a>
          </Box>
        </Flex>

        <Flex sx={{ flexWrap: "wrap", justifyContent: "space-around" }}>
          {podcasts.map((podcast) => (
            <PodcastItem podcast={podcast} />
          ))}
        </Flex>
      </Container>
    </Layout>
  );
};

const PodcastItem = ({ podcast }: PodcastProps) => (
  <Box
    p={3}
    marginTop={2}
    sx={{ minWidth: "250px", borderColor: "#000000", width: "30%" }}
  >
    <Themed.a
      href={podcast.link}
      sx={{
        color: `text`,
        ":hover": { color: `primary`, textDecoration: `none` },
      }}
    >
      <StaticImage
        src="../overseas.jpg"
        alt="Overseas podcast logo"
        imgStyle={{ borderRadius: "2%" }}
      />
      <Themed.h6 sx={{ marginTop: "0.5rem", fontSize: "1.1rem" }}>
        {podcast.title}
      </Themed.h6>
    </Themed.a>
    <Themed.p
      sx={{
        marginTop: "0.3rem",
        fontSize: "0.9rem !important",
        lineHeight: "1.6em",
      }}
    >
      {podcast.description}
    </Themed.p>
    <Themed.p
      sx={{
        marginTop: "0.3rem",
        fontSize: "15px !important",
        lineHeight: "1.6em",
        color: "var(--theme-ui-colors-textMuted)",
      }}
    >
      {podcast.date}
    </Themed.p>
  </Box>
);

export default Homepage;
